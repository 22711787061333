import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { PlusIcon } from '@heroicons/react/solid'
import AdvanceShiftEditing from './AdvanceShiftEditing'
import useReadWithGet from '../../hooks/read/useReadWithGetMethod'
import { formatTime } from '../../utility'
import usePut from '../../hooks/usePut'
import PrimaryLoader from '../../components/common/PrimaryLoader'
import Modals from '../../components/modal/Modals'
import toast, { Toaster } from 'react-hot-toast'


const Shifts = () => {
    let initialData = {
        limit: 50,
        searchQuery: '',
    }
    const [isOpenAdvanceShift, setIsOpenAdvanceShift] = useState(false)
    const [isOpenShiftDelete, setIsOpenShiftDelete] = useState(false)
    const [selectedShiftId, setSelectedShiftId] = useState(null)
    const [mode, setMode] = useState(null)
    const { handleAuth } = usePut({
        url: 'shift_edit',
        refreshUrl: 'shift_listing',
        onSuccess: () => {
            setIsOpenAdvanceShift(true)
        }
    })
    const { handleAuth: deleteShift } = usePut({
        url: 'shift_disabled',
        refreshUrl: 'shift_listing',
        onSuccess: () => {
            setIsOpenShiftDelete(false)
            toast.success('Shift Deleted Successfully')
        },
        onError: () => {
            setIsOpenShiftDelete(false)
            toast.error('There Was An Error While Deleting Please Try Again Later')
        }
    })
    const handleDelete = () => {
        let data = { id: selectedShiftId }
        deleteShift.mutate(data)
    }
    const handleCreateShift = () => {
        setMode(null)
        setIsOpenAdvanceShift(true)
    }
    const { list } = useReadWithGet({
        url: "shift_listing",
        initialData,
    })
    const handleDeleteModal = (id) => {
        setSelectedShiftId(id)
        setIsOpenShiftDelete(true)
    }
    const handleClickedAdvanceShift = (id) => {
        let data = { id }
        setMode('edit')
        handleAuth.mutate(data)
    }

    return (
        <Layout>
            {list.isLoading &&
                <div className='flex items-center justify-center fixed w-full h-full top-0 left-0 bg-[#ffffffc9] z-50'>
                    <PrimaryLoader />
                </div>
            }
            <Toaster />
            <div className='p-5'>
                <div>
                    <button onClick={handleCreateShift} className=' rounded-md bg-color1 px-8 py-2 text-white'>
                        <div className='flex items-center justify-center space-x-2'>
                            <PlusIcon className='w-6 h-6' />
                            <span className=' font-semibold'>Add Shift</span>
                        </div>
                    </button>
                </div>
                <div className="grid grid-cols-3 gap-5 mt-5">
                    {list.data?.data.map((shiftData, shiftIdx) => {
                        const { id, shiftName, shiftInTime, shiftOutTime, workingTypeMode, working_hours,maximum_working_hours,minimum_working_hours } = shiftData
                        return <div key={shiftIdx} className='w-full rounded-md border border-gray-300 p-4'>
                            <div>
                                <p className=''>
                                    <label className='text-gray-700 font-semibold text-lg'>Shift Name: </label>
                                    <span>{shiftName}</span>
                                </p>
                                {workingTypeMode === 'lenient'
                                    ? <div className=''>
                                        <p>
                                            <label className='text-gray-700 font-semibold text-xs'>Maximum working hour: </label>
                                            <span className='text-gray-700 font-semibold text-xs'>{maximum_working_hours} hr</span>
                                        </p>
                                        <p>
                                            <label className='text-gray-700 font-semibold text-xs'>Minimum working hour: </label>
                                            <span className='text-gray-700 font-semibold text-xs'>{minimum_working_hours} hr</span>
                                        </p>
                                    </div>
                                    :
                                    <p>
                                        <label className='text-gray-700 font-semibold text-lg'>Shift Time: </label>
                                        <span className=''>
                                            <span className='mx-1'>
                                                {formatTime(shiftInTime)}
                                            </span>
                                            -
                                            <span className='mx-1'>
                                                {formatTime(shiftOutTime)}
                                            </span>
                                        </span>
                                    </p>
                                }
                                <div className=' space-x-2'>
                                    <button onClick={() => handleClickedAdvanceShift(id)} className=' px-6 py-2 bg-cyan-500 text-white rounded-md text-xs mt-5'>View Shift Detail</button>
                                    <button onClick={() => handleDeleteModal(id)} className=' px-6 py-2 border border-red-600 rounded-md text-xs mt-5'>Delete Shift</button>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <AdvanceShiftEditing mode={mode} shiftData={handleAuth.data?.data?.data[0]} open={isOpenAdvanceShift} onClose={() => setIsOpenAdvanceShift(false)} />
            <Modals show={isOpenShiftDelete}>
                <div>
                    <p className=' roboto text-center font-bold text-2xl text-gray-700'>Are you sure you want to delete this shift?</p>
                    <div className=" mt-12 space-x-3 text-center ">
                        <button className='px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800' onClick={handleDelete}>Yes</button>
                        <button className='px-4 py-2 rounded-md font-semibold  border' onClick={() => setIsOpenShiftDelete(false)}>Cancel</button>
                    </div>
                </div>
            </Modals>
        </Layout>
    )
}

export default Shifts