import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useRead from '../../hooks/useRead'
import AdvanceEmploymentEditing from './AdvanceEmploymentEditing'
import PrimaryLoader from '../../components/common/PrimaryLoader'
import { Toaster } from 'react-hot-toast'

const SingleEmployeeDetail = () => {
    const [isOpenAdvanceEmployment, setIsOpenAdvanceEmployment] = useState(false)
    const { id, username } = useParams()
    const navigate = useNavigate()
    const { list } = useRead({
        url: 'employeedetails_edit',
        initialData: { id }
    })
    const handleOpenAdvanceMapping = () => {
        setIsOpenAdvanceEmployment(true)
    }
    let type = list?.data?.data.type_of_employee
    return (
        <Layout>
            <Toaster />
            {list.isLoading 
            ? <div className=' flex items-center justify-center mt-10'> <PrimaryLoader /></div> 
            : 
            <>
                <div className=' px-4 py-4 flex justify-between sticky bg-white top-16 shadow-lg'>
                    <button onClick={() => navigate(-1, { replace: true })} className='flex items-center hover:underline'>
                        <ChevronLeftIcon className='w-6 h-6' />
                        <span className='font-semibold'>Back</span>
                    </button>
                    <div className=' space-x-5'>
                        <button onClick={handleOpenAdvanceMapping} className='px-5 py-2 bg-orange-300 rounded font-semibold text-sm'>Assign New Shift</button>
                        <Link to={`/employee-promotion/${id}/${username}`} className='px-5 py-2 bg-blue-300 rounded font-semibold text-sm'>Promote/Reassign</Link>
                        <Link to={`/employee-salary-details/${id}/${username}`} className='px-5 py-2 bg-yellow-300 rounded font-semibold text-sm'>Salary Details</Link>
                    </div>
                </div>
                <div className='px-4 space-y-4 my-5'>
                    <div>
                        <div className='grid grid-cols-2 gap-5'>
                            <table className='w-full'>
                                <thead>
                                    <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'> {type} Personal Details</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>First Name</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.first_name || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Last Name</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.last_name || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Email</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.email || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Date Of Birth</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.DOB || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Gender</td>
                                        <td className='text-sm border py-3 px-4 capitalize'>{list?.data?.data.gender || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Mobile Number</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.mobile_number || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Alternate Number</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.home_phone || '----'}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='w-full'>
                                <thead>
                                    <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'>{type} Address Details</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Address Line 1</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.address_1 || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Address Line 2</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.address_2 || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Pincode</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.pincode || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>City</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.city || '----'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <div className='grid grid-cols-2 gap-5'>
                            <table className='w-full'>
                                <thead>
                                    <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'>{type} Bank Details</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Bank Name</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.bank_name || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Account Number</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.account_number || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>IFSC Code</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.ifsc_code || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Pan Number</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.pan_number || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Aadhar Number</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.aadhar_number || '----'}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='w-full'>
                                <thead>
                                    <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'>{type} Work Details</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Educational Qualification</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.qualification || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Work Experience</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.work_experience || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Designation</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.designation || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Department</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.department || '----'}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div>
                        <div className='grid grid-cols-2 gap-5'>
                            <table className='w-full'>
                                <thead>
                                    <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white capitalize'>{type} Statutory Components</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Employees' Provident Fund</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.epf === '1' ? 'Applicable' : 'Not Applicable'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>PF Number</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.pf_account_number || '----'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Professional Tax</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.professional_tax === '1' ? 'Applicable' : 'Not Applicable'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>Employees' State Insurance</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.esic === '1' ? 'Applicable' : 'Not Applicable'}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-xs'>ESIC Number</td>
                                        <td className='text-sm border py-3 px-4'>{list?.data?.data.esi_insurance_number || '----'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <div>
                        <div className='grid grid-cols-2 gap-5'>
                            <table className='w-full'>
                                <thead>
                                    <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Salary Details</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Gross Salary</td>
                                        <td className='border py-3 px-4'>Employee Name</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Professional Tax</td>
                                        <td className='border py-3 px-4'>Designation</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Net Salary</td>
                                        <td className='border py-3 px-4'>Department</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Total Leave</td>
                                        <td className='border py-3 px-4'>listData?.date_of_joining</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> */}
                </div>
            </>
            }
            <AdvanceEmploymentEditing onClose={() => setIsOpenAdvanceEmployment(false)} open={isOpenAdvanceEmployment} />
        </Layout>
    )
}

export default SingleEmployeeDetail