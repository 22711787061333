import React from 'react'
import Layout from '../../../components/layout/Layout'
import Radio from '../../../components/radio/Radio'

const ShiftsSettings = () => {
    return (
        <Layout>
            <div className='p-5'>
                <div>
                    <h1 className='text-2xl font-semibold'>General Settings</h1>
                </div>
                <div className='mt-4'>
                    <form className=' space-y-8'>
                        <div className='grid grid-cols-3 gap-5'>
                            <div>
                                <div className=''>
                                    <label className='label'>Effective from: </label>
                                    <input type="date" name='title' required={true} className='input' placeholder='Enter Shift name ' />
                                </div>
                            </div>
                            <div>
                                <div className=''>
                                    <label className='label'>Default shift time: </label>
                                    <select name="" className='input'>
                                        <option>--Select Option--</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-2xl font-semibold'>Working Hours</h1>
                        </div>
                        <div>
                            <div className=''>
                                <label className='label'>Total hours calculation: </label>
                                <div className="">
                                    <div className=" flex gap-x-4" >
                                        <Radio label={"First Check-in & Last Check-out"} name={"total_hour_calculation"} value={"enable"} />
                                        <Radio label={"Every Valid Check-in & Check-out"} name={"total_hour_calculation"} value={"disable"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className=''>
                                <label className='label'>Minimum hours required for day: </label>
                                <div className="">
                                    <div className=" flex gap-x-4" >
                                        <Radio label={"Strict Mode"} name={"minimum_hours_require_for_day"} value={"enable"} />
                                        <Radio label={"Lenient Mode (Expected Hours)"} name={"minimum_hours_require_for_day"} value={"disable"} />
                                    </div>
                                </div>
                            </div>
                            <div className=' inline-block'>
                                <div className='border border-gray-200 bg-gray-100  px-8 py-4 mt-4 space-x-4 flex items-center'>
                                    <div className=''>
                                        <label className='label'>Full Day: </label>
                                        <div className="">
                                            <input type="text" name='title' required={true} className='input' placeholder='Enter Full Day ' />
                                        </div>
                                    </div>
                                    <div className=''>
                                        <label className='label'>Half Day: </label>
                                        <div className="">
                                            <input type="text" name='title' required={true} className='input' placeholder='Enter Half Day ' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-2xl font-semibold'>Pay Days/Hours Calculation:</h1>
                        </div>
                        <div className=' space-y-3'>
                            <div className=''>
                                <label className='label'>{`Include Weekend(s)`} </label>
                                <div className="">
                                    <div className=" flex gap-x-4" >
                                        <Radio label={"Enable"} name={"include_weekends"} value={"enable"} />
                                        <Radio label={"Disable"} name={"include_weekends"} value={"disable"} />
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <label className='label'>{`Include Holidays`} </label>
                                <div className="">
                                    <div className=" flex gap-x-4" >
                                        <Radio label={"Enable"} name={"include_weekends"} value={"enable"} />
                                        <Radio label={"Disable"} name={"include_weekends"} value={"disable"} />
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <label className='label'>{`Include Leave`} </label>
                                <div className="">
                                    <div className=" flex gap-x-4" >
                                        <Radio label={"Enable"} name={"include_weekends"} value={"enable"} />
                                        <Radio label={"Disable"} name={"include_weekends"} value={"disable"} />
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <label className='label'>{`Carry over balance hours in overtime report`} </label>
                                <div className="">
                                    <div className=" flex gap-x-4" >
                                        <Radio label={"Enable"} name={"include_weekends"} value={"enable"} />
                                        <Radio label={"Disable"} name={"include_weekends"} value={"disable"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h4 className='mb-2 font-semibold'>Late night work hours</h4>
                            <label className=' space-x-2'>
                                <input type="checkbox" name="" id="" />
                                <span className='text-gray-600'>When shift margin feature is enabled, worked hours outside shift margin will be treated as overtime.</span>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default ShiftsSettings