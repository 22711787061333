import React, { useState } from 'react'
import Layout from '../../../components/layout/Layout'
import { PlusIcon } from '@heroicons/react/solid'
import ShiftRotationDrawer from './ShiftRotationDrawer'
import { Modal } from '@mui/material'
import EmployeeAssignModule from '../../../components/EmployeeAssignModule'

const ShiftRotation = () => {
    const [isOpenShiftMapping, setIsOpenShiftMapping] = useState(false)
    const handleOpenShiftMap = () => {
        setIsOpenShiftMapping(true)
    }
    return (
        <Layout>
            <div className='p-5'>
                <div>
                    <button onClick={handleOpenShiftMap} className=' rounded-md bg-color1 px-8 py-2 text-white'>
                        <div className='flex items-center justify-center space-x-2'>
                            <PlusIcon className='w-6 h-6' />
                            <span className=' font-semibold'>Configure Rotation</span>
                        </div>
                    </button>
                </div>
            </div>
            <div class="max-w-full overflow-x-auto px-4">
                <table class="table-auto w-full">
                    <thead>
                        <tr class=" bg-[color:var(--color1)] text-center">
                            <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Employee</th>
                            <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">{`Shift(s)`}</th>
                            <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4border-r border-transparent">Modified On</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <ShiftRotationDrawer onClose={() => setIsOpenShiftMapping(false)} open={isOpenShiftMapping} />
        </Layout>
    )
}

export default ShiftRotation