import React from 'react'
import Layout from '../../components/layout/Layout'
import useRead from '../../hooks/useRead'
import { useParams } from 'react-router-dom'
import Moment from 'react-moment'
import Pagination from '../../components/pagination'

const LeaveEditHistory = () => {
    let { id } = useParams()
    let initialData = {
        id,
        search_query: '',
        limit: 50,
        page_number: 0
    }
    const { list, paramsObject, setGetListParams } = useRead({
        url: 'getLeaveEditHistory',
        initialData,
        onSuccess: (res) => {
            console.log(res);
        }
    })
    const handlePageClick = (val) => { }
    return (
        <Layout>
            <div className='px-5 pb-20'>
                <div className="max-w-full overflow-x-auto mt-8">
                    <table className="table-auto w-full">
                        <thead>
                            <tr className=" bg-[color:var(--color1)] text-center">
                                <th className="text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Reason</th>
                                <th className="text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Leave Type</th>
                                <th className="text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">From Data</th>
                                <th className="text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">To Date</th>
                                <th className="text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Edited On</th>
                                <th className="text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Approved On</th>
                                <th className="text-sm font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Leave Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.data?.data.map((data, idx) => {
                                const { id } = data
                                return <tr key={idx}>
                                    <td className="text-center text-dark font-medium text-sm py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                        {data.reason || '-----'}
                                    </td>
                                    <td className="text-center text-dark font-medium text-sm py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{data.leave_type}</td>
                                    <td className=' text-center border-b bg-[#F3F6FF]'>
                                        <small className=''><Moment unix format='DD/MM/YYYY'>{data.from_date}</Moment></small>
                                    </td>
                                    <td className=' text-center border-b bg-[#F3F6FF]'>
                                        <small className=''><Moment unix format='DD/MM/YYYY'>{data.to_date}</Moment></small>
                                    </td>
                                    <td className=' text-center border-b bg-[#F3F6FF]'>
                                        <small className=''><Moment unix format='DD/MM/YYYY'>{data.edited_on}</Moment></small>
                                    </td>
                                    <td className=' text-center border-b bg-[#F3F6FF]'>
                                        <small className=''><Moment unix format='DD/MM/YYYY'>{data.approved_date}</Moment></small>
                                    </td>
                                    <td className="text-center select-none text-dark font-medium text-sm py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                        {data.leave_status === '1' ? <span className='bg-green-700 text-green-200 px-2 py-1 rounded-full text-xs'>Approved</span>
                                            : data.leave_status === '2' ? <span className='bg-yellow-600 text-yellow-200 px-2 py-1 rounded-full text-xs'>Pending</span>
                                                : <span className='bg-red-700 text-red-200 px-2 py-1 rounded-full text-xs'>Rejected</span>}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='px-5 mt-5'>
                    <Pagination
                        currentPage={+paramsObject.page_number}
                        lengthofItems={list.data?.count}
                        limit={paramsObject.limit}
                        onPageChange={handlePageClick}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default LeaveEditHistory