import { ABOVE_LIMIT_TAX, PERCENTILE, TAX, TDS } from "../config";
import moment from 'moment';

let characters = '';
let passwordLength = 8;
let grossSalary = 0
characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!0123456789';
// characters = '0123456789';


export const taxCalculation = (employeeData) => {
    const { gst, gender, salary, tds, type_of_employee } = employeeData
    if (type_of_employee === "consultant") {
        if (salary === ' ' || !salary || salary <= 1) return { ...employeeData, gross_salary: 0, tds: 0, gst: 0, }
        return { ...employeeData, gross_salary: salary - gst - (TDS / PERCENTILE) * salary, tds: (TDS / PERCENTILE) * salary }
    } else if (type_of_employee === "employee") {
        if (salary === ' ' || !salary || salary <= 1) return { ...employeeData, gross_salary: 0, professional_tax: 0 }
        if (gender === "male") {
            if (salary <= 7500) return { ...employeeData, professional_tax: 0, gross_salary: salary }
            if (salary >= 7501 && salary <= 10000) return { ...employeeData, professional_tax: TAX, gross_salary: salary - TAX - tds }
            if (salary >= 10001) return { ...employeeData, professional_tax: ABOVE_LIMIT_TAX, gross_salary: salary - ABOVE_LIMIT_TAX - tds }
            return { ...employeeData, professional_tax: TAX, gross_salary: grossSalary }
        } else if (gender === "female") {
            if (salary <= 10000) return { ...employeeData, professional_tax: 0, gross_salary: salary }
            grossSalary = salary
            if (salary >= 10001) return { ...employeeData, professional_tax: ABOVE_LIMIT_TAX, gross_salary: salary - ABOVE_LIMIT_TAX - tds }
        }
    }
    return { ...employeeData }
}


function generatePasswordNew() {
    const length = 8;
    const specialCharacters = '$@!';
    const numbers = '0123456789';

    // Generate a random alphabetical character for the first and last positions
    const randomAlphabeticChar = () => {
        const alphabeticCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return alphabeticCharacters.charAt(Math.floor(Math.random() * alphabeticCharacters.length));
    };

    const firstChar = randomAlphabeticChar();
    const lastChar = randomAlphabeticChar();

    // Generate a random special character
    const randomSpecialChar = specialCharacters.charAt(Math.floor(Math.random() * specialCharacters.length));

    // Generate a random number
    const randomNumber = numbers.charAt(Math.floor(Math.random() * numbers.length));

    // Generate the remaining part of the password with alphanumeric characters
    const remainingLength = length - 4; // 2 alphabets, 1 special character, 1 number
    let alphanumericPart = '';
    const alphanumericCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    for (let i = 0; i < remainingLength; i++) {
        const randomAlphanumericChar = alphanumericCharacters.charAt(Math.floor(Math.random() * alphanumericCharacters.length));
        alphanumericPart += randomAlphanumericChar;
    }

    // Shuffle the alphanumeric part to add randomness
    const shuffledAlphanumericPart = alphanumericPart.split('').sort(() => Math.random() - 0.5).join('');

    // Combine all parts to form the final password
    const password = firstChar + randomSpecialChar + shuffledAlphanumericPart + randomNumber + lastChar;

    return password;
}



export const generatePassword = (passwordProps) => {
    const password = generatePasswordNew();
    return password;
}

const setUpperCase = isUpperCase => {
    if (isUpperCase) {
        characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    return '';
}

const setLowerCase = isLowerCase => {
    if (isLowerCase) {
        characters += 'abcdefghijklmnopqrstuvwxyz';
    }
    return '';
}

const setSymbols = isSymbol => {
    if (isSymbol) {
        characters += '!@#$%^&*()<>,.?/[]{}-=_+|/';
    }
    return '';
}

const setNumber = isNumeric => {
    if (isNumeric) {
        characters += '0123456789';
    }
    return '';
}

const getRandomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const passwordCharacters = () => {
    let password = '';
    if (characters.length) {
        for (let i = 0; i < passwordLength; i++) {
            password += characters[getRandomInteger(0, characters.length - 1)];
        }
        characters = '';
        passwordLength = 0;
        return password;
    }
}

export const setPasswordLength = length => {
    passwordLength = length;
    return passwordLength;
}

export const formatDateForMonth = (day, dateObject) => {
    let month;
    if (dateObject.getMonth() + 1 < 10) {
        month = '0' + (dateObject.getMonth() + 1);
    }
    else {
        month = dateObject.getMonth() + 1;
    }
    if (day < 10) {
        return '0' + day + "-" + (month) + "-" + (dateObject.getUTCFullYear())
    }
    else {
        return day + "-" + (month) + "-" + (dateObject.getUTCFullYear())
    }
}

export const formatTime = (timeString) => {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}


export const handleTotalLeaveCalculation = (from, to) => {
    let totalLeave = 0
    totalLeave = from ? moment(to).diff(from, 'days') + 1 : 0
    return totalLeave
}

// Function returns "," for number values
export const numberWithComma = (value) => {
    if (!value) return
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export const generateURL = (obj) => {
    // Initialize an array to hold key-value pairs
    const keyValuePairs = [];

    // Loop through the object's properties
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            // Encode the key and value and add them to the array
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(obj[key]);
            keyValuePairs.push(`${encodedKey}=${encodedValue}`);
        }
    }

    // Join the key-value pairs with "&" and add them to the base URL
    const queryString = keyValuePairs.join('&');
    const url = `?${queryString}`;

    return url;
}



export function calculateFractionOfFinancialYear(joiningDate) {
    // Get the current date
    const current = new Date()
    const currentYear = current.getFullYear()
    const currentDate = new Date(joiningDate);
    // console.log(currentDate.getFullYear() + 1);
    // Set the target date to March 31st of the same year
    let targetDate;
    if (currentYear === currentDate.getFullYear()) {
        console.log('h');
        targetDate = new Date((currentDate.getFullYear()), 2, 31); // March is month 2 (0-indexed)
    } else {
        targetDate = new Date(currentDate.getFullYear(), 2, 31); // March is month 2 (0-indexed)
    }

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - currentDate;

    // Calculate the number of days from milliseconds
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference === NaN ? '' : daysDifference
}