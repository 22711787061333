import React, { useState } from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'
import "./layout.css"

function Layout({ children }) {
    const [isSideBarShow,setIsSidebarShow] = useState(false)
    return (
        <div className='layout'>
            <Sidebar show={isSideBarShow}/>
            <div className='content-wrapper ml-64'>
                <Header onClick={() => setIsSidebarShow(!isSideBarShow)}/>
                {children}
            </div>
        </div>
    )
}

export default Layout