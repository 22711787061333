import Drawer from '@mui/material/Drawer';
import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/solid';

const EmployeePromotionModal = ({ open, onClose }) => {
    return (
        <Drawer anchor={'right'} open={open} onClose={onClose}>
            <div className='h-full bg-white p-8' style={{ width: 'calc(100vw - 300px)' }}>
                <form className=' space-y-8'>
                    <div>
                        <div className='flex items-center space-x-3 pb-5 border-b border-gray-200'>
                            <ArrowLeftIcon className=' text-gray-700 w-8 h-8 cursor-pointer' onClick={onClose} />
                            <p className='text-gray-700 text-3xl font-semibold'>Employee Promotion</p>
                        </div>
                        <div className='grid grid-cols-3 gap-x-5 py-5'>
                            <div>
                                <div className=''>
                                    <label className='label'>Current Employment Type: </label>
                                    <span className='text-lg font-semibold block border border-gray-300 rounded-md py-1 px-3'>Probation</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label className='label'>Shift To? </label>
                                    <select name="" className='input'>
                                        <option value="">--Select Employment Type--</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type='button' className='w-full py-8 border-2 border-dashed border-gray-400 rounded-md'>
                                <PlusIcon className='w-12 h-12 text-gray-600 mx-auto'/>
                                <span className='text-gray-600 font-semibold'>Add Document</span>
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </Drawer>
    )
}

export default EmployeePromotionModal