import React, { useEffect, useState } from 'react'
import { postEmployeeDetails } from '../../api/employeeDetails'
import _ from 'lodash'
import Layout from '../../components/layout/Layout'
// import Radio from '../../components/radio/Radio'
import { Link, useLocation } from "react-router-dom";
import Modals from '../../components/modal/Modals';
import { calculateFractionOfFinancialYear, generatePassword, taxCalculation, taxCalculationconstultant, taxCalculationEmployee } from '../../utility';
import { type } from '@testing-library/user-event/dist/type'
import spinner from '../../assets/images/spinner.gif'
import Cookies from 'universal-cookie';
import { getUserName } from '../../api'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import LeavesCalculation from './LeavesCalculation';
import { useForm } from 'react-hook-form';
import useRead from '../../hooks/useRead';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FULL_TIME_EMPLOYEE, FULL_TIME_EMPLOYEE_LEAVES, NOTICE_PERIOD_CONSULTANT, NOTICE_PERIOD_EMPLOYEE, ON_SIDE, ON_SITE_EMPLOYEE_LEAVES, PART_TIME_EMPLOYEE, PART_TIME_EMPLOYEE_LEAVES, REMOTE_EMPLOYEE_LEAVES } from '../../config';
const cookies = new Cookies
let val = [0, 0, 0]
let IS_REQUIRED = true
function AddEmployeeDetails() {
    const leaveDataRef = React.useRef(null)
    const [role, setRole] = useState('')
    // const initialState = {
    //     first_name: 'Mukesh',
    //     last_name: 'Swamy',
    //     address_1: 'Address Line 1',
    //     address_2: 'Address Line 2',
    //     pincode: '1234',
    //     city: 'Mumbai',
    //     email: 'email@email.com',
    //     DOB: '2022-08-06',
    //     gender: 'Male',
    //     mobile_number: '1234567890',
    //     home_phone: '1234567890',
    //     bank_name: 'Sbi',
    //     ifsc_code: '12345',
    //     account_number: '1234567890',
    //     pan_number: '123456',
    //     aadhar_number: '12345',
    //     qualification: 'Education',
    //     work_experience: '1',
    //     designation: 'Web developer',
    //     department: 'IT',
    //     date_of_joining: '2022-08-06',
    //     date_of_leaving: '',
    //     username_surfix: '123',
    //     username_prefix: '123',
    //     password: '1234567890',
    //     salary: '128',
    //     professional_tax: '12',
    //     tds: '12',
    //     gross_salary: '123',
    //     type_of_employee: 'Consultant',
    //     cl_leave: '',
    //     pl_leave: '',
    //     sl_leave: '',
    //     total_leave: '',
    // }
    const initialState = {
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        pincode: '',
        city: '',
        email: '',
        DOB: '',
        gender: '',
        mobile_number: '',
        home_phone: '',
        bank_name: '',
        ifsc_code: '',
        account_number: '',
        pan_number: '',
        aadhar_number: '',
        qualification: '',
        work_experience: '',
        designation: '',
        department: '',
        date_of_joining: '',
        username_surfix: '',
        username_prefix: '',
        password: '',
        salary: '',
        professional_tax: 0,
        tds: 0,
        gst: 0,
        gst_number: '',
        isConsultant: false,
        gross_salary: 0,
        type_of_employee: '',
        pl_leave: '',
        dl_leave: '',
        cl_leave: '',
        sl_leave: '',
        ol_leave: '',
        total_leave: '',
        employment_id: '',
        shift_id: '',
        probationDate: '',
        employmentType: '',
        subEmploymentType: '',
        statutory: {
            epf: {
                isEpf: false,
                pf_account_number: '',
                pf_uan_number: '',
                is_contribute_to_scheme: false
            },
            esi: {
                isEsi: false,
                esi_number: ''
            },
            isPt: false
        }
    }
    const [isLoading, setIsLoading] = useState(false)
    const [employeDetail, setEmployeDetail] = useState(initialState)
    const [isShowingModal, setIsShowingModal] = useState(false)
    const [isConsultant, setIsConsultant] = useState(false)
    const [educationFile, setEducationFile] = useState(null)
    const [workDoc, setWorkDocs] = useState(null)
    const [leaveDetails, setLeaveDetails] = useState(null)
    const [type,setType] = useState('')
    const handleSetEmployeDetail = (e) => {
        const value = e.target.value
        if (e.target.name === 'cl_leave' || e.target.name === 'pl_leave' || e.target.name === 'sl_leave' || e.target.name === 'dl_leave' || e.target.name === 'ol_leave') {
            const id = e.target.getAttribute("data-id")
            val[id] = +value
            const sum = val.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0,
            );
            let data = {
                ...employeDetail,
                [e.target.name]: +value,
                total_leave: sum
            }
            setEmployeDetail(data)
            return data
        }
        else {
            setEmployeDetail({
                ...employeDetail,
                [e.target.name]: value
            })
        }
        if (e.target.name === 'gst_number') {
            let data = { ...employeDetail }
            if (value === '' || !value || value === undefined) {
                data = { ...employeDetail, gst: 0, [e.target.name]: value }
                let calculatedData = taxCalculation(data)
                setEmployeDetail(calculatedData)
            } else {
                data = {
                    ...employeDetail,
                    [e.target.name]: value
                }
                setEmployeDetail(data)
            }
        }
        if (e.target.name === 'date_of_joining') {
            calculateProbationDate({ value, text: employeDetail.employmentType })
            setLeaves(value, employeDetail.employmentType)
        }
        if (e.target.name === 'employment_id') {
            const selectElement = e.target;
            const selectedOption = selectElement.options[selectElement.selectedIndex];
            const selectedText = selectedOption.getAttribute("data-tag");

            calculateProbationDate({
                value: employeDetail.date_of_joining,
                text: selectedText
            })
            setLeaves(employeDetail.date_of_joining, selectedText)
            setEmployeDetail(prev => {
                return {
                    ...prev,
                    employmentType: selectedText,
                    subEmploymentType: selectedOption.innerHTML
                }
            })
        }
        return {
            ...employeDetail,
            [e.target.name]: value,
        }
    }

    const setLeaves = (value, text) => {
        if (employeDetail.type_of_employee === 'consultant') {
            if (text === 'Office') {
                setValue('leaves.ol.annual', ON_SITE_EMPLOYEE_LEAVES.ol)
                onChangeLeaveCalculation(ON_SITE_EMPLOYEE_LEAVES.ol, 'leaves.ol.available', value)
            } else if (text === 'Remote') {
                setValue('leaves.public.available', REMOTE_EMPLOYEE_LEAVES.holiday)
            }
        } else if (employeDetail.type_of_employee === 'employee') {
            if (text === 'Full Time Office') {
                setValue('leaves.pl.annual', FULL_TIME_EMPLOYEE_LEAVES.pl)
                onChangeLeaveCalculation(FULL_TIME_EMPLOYEE_LEAVES.pl, 'leaves.pl.available', value)
                setValue('leaves.cl.annual', FULL_TIME_EMPLOYEE_LEAVES.cl)
                onChangeLeaveCalculation(FULL_TIME_EMPLOYEE_LEAVES.cl, 'leaves.cl.available', value)
                setValue('leaves.sl.annual', FULL_TIME_EMPLOYEE_LEAVES.sl)
                onChangeLeaveCalculation(FULL_TIME_EMPLOYEE_LEAVES.sl, 'leaves.sl.available', value)
            } else if (text === 'Part Time Office') {
                setValue('leaves.pl.annual', PART_TIME_EMPLOYEE_LEAVES.pl)
                onChangeLeaveCalculation(PART_TIME_EMPLOYEE_LEAVES.pl, 'leaves.pl.available', value)
                setValue('leaves.cl.annual', PART_TIME_EMPLOYEE_LEAVES.cl)
                onChangeLeaveCalculation(PART_TIME_EMPLOYEE_LEAVES.cl, 'leaves.cl.available', value)
                setValue('leaves.sl.annual', PART_TIME_EMPLOYEE_LEAVES.sl)
                onChangeLeaveCalculation(PART_TIME_EMPLOYEE_LEAVES.sl, 'leaves.sl.available', value)
            }
        }
    }
    const handleConsultant = (e) => {
        let type = e.target.value
        if (type === 'consultant') {
            setIsConsultant(true)
            let data = {
                ...employeDetail,
                [e.target.name]: e.target.value,
                salary: 0,
                professional_tax: 0,
                tds: 0,
                gst: 0,
                gross_salary: 0,
                cl_leave: '',
                pl_leave: '',
                sl_leave: '',
                ol_leave: '',
                total_leave: '',
                probationDate: '',
                date_of_joining: '',
            }
            setEmployeDetail(data)
            setValue('leaves.ol.annual', '')
            setValue('leaves.pl.annual', '')
            setValue('leaves.cl.annual', '')
            setValue('leaves.sl.annual', '')
            setValue('leaves.ol.available', '')
            setValue('leaves.pl.available', '')
            setValue('leaves.cl.available', '')
            setValue('leaves.sl.available', '')
        }
        else if (type === 'employee') {
            setIsConsultant(false)
            let data = {
                ...employeDetail,
                [e.target.name]: e.target.value,
                salary: 0,
                professional_tax: 0,
                tds: 0,
                gst: 0,
                gross_salary: 0,
                cl_leave: '',
                pl_leave: '',
                sl_leave: '',
                ol_leave: '',
                total_leave: '',
                probationDate: '',
                date_of_joining: '',
            }
            setEmployeDetail(data)
            setValue('leaves.ol.annual', '')
            setValue('leaves.pl.annual', '')
            setValue('leaves.cl.annual', '')
            setValue('leaves.sl.annual', '')
            setValue('leaves.ol.available', '')
            setValue('leaves.pl.available', '')
            setValue('leaves.cl.available', '')
            setValue('leaves.sl.available', '')
        }
        setType(e.target.value)
    }
    const handleTax = (e) => {
        let data = taxCalculation(handleSetEmployeDetail(e))
        setEmployeDetail(data)
        // console.log(data)
    }
    const handleGeneratePassword = () => {
        var password = generatePassword({
            uppercase: false,
            lowercase: false,
            symbols: false,
            numbers: true
        })
        let data = {
            ...employeDetail,
            password: password,
        }
        setEmployeDetail(data)
        return data
    }
    const { control, handleSubmit, setValue } = useForm()
    const handleSubmitEmployeDetail = (e) => {
        e.preventDefault()
        const leavesData = async (leavesData) => {
            setIsLoading(true)
            let employeeFormData = {
                ...employeDetail,
                ...leavesData
            }
            if(!employeDetail.employmentType){
                delete employeeFormData.leaves;
            }
            postEmployeeDetails(employeeFormData, educationFile, workDoc)
                .then((res) => {
                    if (res.status === 200) {
                        setIsShowingModal(true)
                        setIsLoading(false)
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    alert(Object.keys(err.response.data.data).map(key => {
                        return err.response.data.data[key]
                    }))
                })
        };
        handleSubmit(leavesData)()
    }
    const handleGetUserName = (role) => {
        getUserName(role)
            .then((res) => {
                if (res.status === 200) {
                    let data = {
                        ...employeDetail,
                        username_prefix: role === 'employee' ? 'AMB_E_' : 'AMB_C_',
                        username_surfix: res?.data.username_surfix
                    }
                    setEmployeDetail(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useEffect(() => {
        if (type) {
            handleGetUserName(employeDetail.type_of_employee)
        }
    }, [type])

    const onChangeLeaveCalculation = (value, type, date) => {
        // let numberOfDaysRemaining = calculateFractionOfFinancialYear(employeDetail.date_of_joining ? new Date(employeDetail.date_of_joining) : new Date(date))
        let numberOfDaysRemaining = calculateFractionOfFinancialYear(new Date(date))
        let availLeave = isNaN(numberOfDaysRemaining) ? '' : Math.round((+value / 365) * numberOfDaysRemaining);
        setValue(type, Math.abs(availLeave))
    }
    const { list: employmentList } = useRead({
        url: 'employment_listing',
        initialData: {
            limit: 50,
            search_query: ''
        }
    })
    const { list: shiftList, } = useRead({
        url: 'shift_listing',
        initialData: {
            limit: 50,
            search_query: ''
        }
    })
    const [statutoryFormVisiblity, setStatutoryFormVisiblity] = useState({
        isEpf: false,
        isEsi: false,
    })
    const handleChangeStatutoryComponent = (e) => {
        let checked = e.target.checked
        setStatutoryFormVisiblity(prev => {
            return {
                ...prev,
                [e.target.name]: checked
            }
        })
        let data = employeDetail.statutory
        if (e.target.name === 'isEpf') {
            data['epf']['isEpf'] = checked
        } else {
            data['esi']['isEsi'] = checked
        }
        setEmployeDetail({ ...employeDetail })
    }
    const handleChangeStatutoryForm = (e, key) => {
        let name = e.target.name
        let value = e.target.value
        let copyData = employeDetail
        copyData.statutory[key][name] = value
        setEmployeDetail({ ...employeDetail })
    }
    const handleChangeStatutoryCheckbox = (e, key) => {
        let name = e.target.name
        let value = e.target.checked
        let copyData = employeDetail
        if (key === 'isPf') {
            copyData.statutory[key][name] = value
        } else {
            copyData.statutory[name] = value
        }
        setEmployeDetail({ ...employeDetail })
    }
    const handleDate = (value) => {
        setEmployeDetail(prev => {
            return {
                ...prev,
                probationDate: value
            }
        })
    }
    const calculateProbationDate = ({ value, text }) => {
        if (!value) return
        if (employeDetail.type_of_employee === 'consultant') {
            const currentDate = new Date(value);
            const fortyFiveDaysFromToday = new Date(currentDate);
            fortyFiveDaysFromToday.setDate(currentDate.getDate() + ON_SIDE);
            setEmployeDetail(prev => {
                return {
                    ...prev,
                    probationDate: fortyFiveDaysFromToday
                }
            })
        } else {
            if (text === 'Part Time Office') {
                const currentDate = new Date(value);
                const fortyFiveDaysFromToday = new Date(currentDate);
                fortyFiveDaysFromToday.setDate(currentDate.getDate() + PART_TIME_EMPLOYEE);
                setEmployeDetail(prev => {
                    return {
                        ...prev,
                        probationDate: fortyFiveDaysFromToday
                    }
                })

            } else if (text === 'Full Time Office') {
                console.log("ren");
                const currentDate = new Date(value);
                const fortyFiveDaysFromToday = new Date(currentDate);
                fortyFiveDaysFromToday.setDate(currentDate.getDate() + FULL_TIME_EMPLOYEE);
                setEmployeDetail(prev => {
                    return {
                        ...prev,
                        probationDate: fortyFiveDaysFromToday
                    }
                })
            }
        }
    }
    const handleCalculateLeaves = () => {
        setLeaveDetails(true)
        setLeaves(employeDetail.date_of_joining, employeDetail.employmentType)
    }
    return (
        <Layout>
            <div className="">
                <div className=" container">
                    <div className=" px-4 py-4 text-start">
                        <Link to={"/employe-details"} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Back</Link>
                    </div>

                    <form action="" method="POST" onSubmit={handleSubmitEmployeDetail}>
                        <small className=' px-4 py-4'><span className=' mandatory'>*</span> Fields are mandatory</small>
                        <div className=" ">
                            <div className="px-4 my-4">
                                <div className='flex items-center space-x-4'>
                                    <p className='label'>User Type:</p>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="type_of_employee"
                                        value={employeDetail.type_of_employee}
                                        onChange={handleConsultant}
                                    >
                                        <FormControlLabel value="consultant" control={<Radio />} label="Consultant" />
                                        <FormControlLabel value="employee" control={<Radio />} label="Employee" />
                                    </RadioGroup>
                                </div>
                            </div>
                            {employeDetail.type_of_employee &&
                                <>
                                    <div className=''>
                                        <div className=" grid grid-cols-2 gap-4 px-4 auth-section">
                                            <div className="">
                                                <label htmlFor="salary" className='label'>Username:<span className='mandatory'>*</span></label>
                                                <input type="text" name='username' className='input disabled:cursor-not-allowed' required={IS_REQUIRED} value={employeDetail.username_prefix + employeDetail.username_surfix} readOnly disabled placeholder='Username' />
                                            </div>
                                            <div className=' grid grid-cols-2 gap-4 '>
                                                <div className="">
                                                    <label htmlFor="gross_salary" className='label'>Password</label>
                                                    <input type="text" name='password' readOnly disabled className='input disabled:cursor-not-allowed' placeholder='Password' value={employeDetail.password} />
                                                </div>
                                                <div className="">
                                                    <label htmlFor="gross_salary" className='label'></label>
                                                    <button type='button' onClick={handleGeneratePassword} className='px-5 py-2 rounded bg-gray-500 mt-9 text-white'>Generate Password</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" grid grid-cols-2 gap-4 p-4 border-b-2 personalDetail-section">
                                            <div className="">
                                                <p className='label'>Gender:<span className='mandatory'>*</span></p>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="gender"
                                                    value={employeDetail.gender}
                                                    onChange={handleTax}
                                                >
                                                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                                                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                                                </RadioGroup>
                                            </div>
                                            <div className="">
                                                <label htmlFor="first_name" className='label'>First Name:<span className='mandatory'>*</span></label>
                                                <input type="text" name='first_name' required={IS_REQUIRED} value={employeDetail.first_name} onChange={handleSetEmployeDetail} className='input' placeholder='Enter first name ' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="last_name" className='label'>Last Name:<span className='mandatory'>*</span></label>
                                                <input type="text" name='last_name' required={IS_REQUIRED} value={employeDetail.last_name} onChange={handleSetEmployeDetail} className='input' placeholder='Enter last name ' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="address_1" className='label'>Address Line 1:<span className='mandatory'>*</span></label>
                                                <input type="text" name='address_1' className='input' required={IS_REQUIRED} value={employeDetail.address_1} onChange={handleSetEmployeDetail} placeholder='Enter address line 1 code' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="address_2" className='label'>Address Line 2:</label>
                                                <input type="text" name='address_2' className='input' value={employeDetail.address_2} onChange={handleSetEmployeDetail} placeholder='Enter address line 2 code' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="pincode" className='label'>Pincode:<span className='mandatory'>*</span></label>
                                                <input type="text" name='pincode' className='input' required={IS_REQUIRED} value={employeDetail.pincode} onChange={handleSetEmployeDetail} placeholder='Pincode' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="city" className='label'>City:<span className='mandatory'>*</span></label>
                                                <input type="text" name='city' className='input' required={IS_REQUIRED} value={employeDetail.city} onChange={handleSetEmployeDetail} placeholder='Enter city ' />
                                            </div>

                                            <div className="">
                                                <label htmlFor="email" className='label'>Email:<span className='mandatory'>*</span></label>
                                                <input type="text" name='email' className='input' required={IS_REQUIRED} value={employeDetail.email} onChange={handleSetEmployeDetail} placeholder='Email' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="DOB" className='label'>Date Of Birth:<span className='mandatory'>*</span></label>
                                                <input type="date" name='DOB' className='input' required={IS_REQUIRED} value={employeDetail.DOB} onChange={handleSetEmployeDetail} placeholder='Enter DOB ' />
                                            </div>
                                            <br />
                                            <div className="">
                                                <label htmlFor="mobile_number" className='label'>Mobile Number:<span className='mandatory'>*</span></label>
                                                <input type="tel" name='mobile_number' maxLength={10} required={IS_REQUIRED} className='input' value={employeDetail.mobile_number} onChange={handleSetEmployeDetail} placeholder='Enter mobile number' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="home_phone" className='label'>Alternate Number:<span className='mandatory'>*</span></label>
                                                <input type="tel" name='home_phone' maxLength={10} className='input' required={IS_REQUIRED} value={employeDetail.home_phone} onChange={handleSetEmployeDetail} placeholder='Alternate Number' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="bank_name" className='label'>Bank Name: <span className='mandatory'>*</span></label>
                                                <input type="text" name='bank_name' className='input' required={IS_REQUIRED} value={employeDetail.bank_name} onChange={handleSetEmployeDetail} placeholder='Bank name' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="ifsc_code" className='label'>IFSC Code:<span className='mandatory'>*</span></label>
                                                <input type="text" name='ifsc_code' className='input' required={IS_REQUIRED} value={employeDetail.ifsc_code} onChange={handleSetEmployeDetail} placeholder='IFSC Code' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="account_number" className='label'>Account Number:<span className='mandatory'>*</span></label>
                                                <input type="tel" name='account_number' className='input' required={IS_REQUIRED} value={employeDetail.account_number} onChange={handleSetEmployeDetail} placeholder='Account number' />
                                            </div>
                                            {isConsultant ? <div className="">
                                                <label htmlFor="account_number" className='label'>GST Number:</label>
                                                <input type="text" name='gst_number' className='input' value={employeDetail.gst_number} onChange={handleSetEmployeDetail} placeholder='GST Number' />
                                            </div> : <br />}
                                            <div className="">
                                                <label htmlFor="pan_number" className='label'>Pan Number:<span className='mandatory'>*</span></label>
                                                <input type="text" name='pan_number' className='input' required={IS_REQUIRED} value={employeDetail.pan_number} onChange={handleSetEmployeDetail} placeholder='Pan number' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="pan_number" className='label'>Aadhar Number:<span className='mandatory'>*</span></label>
                                                <input type="tel" maxLength={12} name='aadhar_number' className='input' required={IS_REQUIRED} value={employeDetail.aadhar_number} onChange={handleSetEmployeDetail} placeholder='Aadhar number' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="qualification" className='label'>Educational Qualification : <span className='mandatory'>*</span></label>
                                                <input type="text" name='qualification' className='input' required={IS_REQUIRED} value={employeDetail.qualification} onChange={handleSetEmployeDetail} placeholder='Enter your qualification' />
                                            </div>
                                            <div className=" ">
                                                <label htmlFor="education_certificates" className='label'>Education Certificates:</label>
                                                <input type="file" name='education_certificates' className='input border-none' onChange={(e) => setEducationFile(e)} placeholder='Enter your qualification' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="work_experience" className='label'>Work Experience : <small>{`(In year)`}</small><span className='mandatory'>*</span></label>
                                                <input type="text" name='work_experience' className='input' required={IS_REQUIRED} value={employeDetail.work_experience} onChange={handleSetEmployeDetail} placeholder='Enter your qualification' />
                                            </div>
                                            <div className=" ">
                                                <label htmlFor="work_documents" className='label'>Work Experience Documents:</label>
                                                <input type="file" name='work_documents' className='input border-none' onChange={(e) => setWorkDocs(e)} placeholder='Enter your work experience' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="designation" className='label'>Designation:<span className='mandatory'>*</span></label>
                                                <input type="text" name='designation' className='input' required={IS_REQUIRED} value={employeDetail.designation} onChange={handleSetEmployeDetail} placeholder='Designation' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="department" className='label'>Department:<span className='mandatory'>*</span></label>
                                                <input type="text" name='department' className='input' required={IS_REQUIRED} value={employeDetail.department} onChange={handleSetEmployeDetail} placeholder='Department' />
                                            </div>
                                            <div>
                                                <label htmlFor="department" className='label'>Type:<span className='mandatory'>*</span></label>
                                                <select name="employment_id" required={IS_REQUIRED} className='input' value={employeDetail.employment_id} onChange={handleSetEmployeDetail}>
                                                    <option value="">--Select Employment Type---</option>
                                                    {employmentList.data?.data.map((item) => {
                                                        const { type_of_employee } = item
                                                        return type_of_employee === employeDetail.type_of_employee && <option key={item.id} value={item.id} data-tag={item?.tag} className=' capitalize'>{item.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div>
                                                <label htmlFor="department" className='label'>Shift:<span className='mandatory'>*</span></label>
                                                <select name="shift_id" required={IS_REQUIRED} className='input' value={employeDetail.shift_id} onChange={handleSetEmployeDetail}>
                                                    <option value="">--Select Shift---</option>
                                                    {shiftList.data?.data.map((item) => {
                                                        return <option key={item.id} value={item.id} className=' capitalize'>{item.shiftName}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="">
                                                <label htmlFor="date_of_joining" className='label'>Date Of Joining:<span className='mandatory'>*</span></label>
                                                <input type="date" name='date_of_joining' className='input' required={IS_REQUIRED} value={employeDetail.date_of_joining} onBlur={handleCalculateLeaves} onChange={handleSetEmployeDetail} placeholder='Date of joining' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="date_of_leaving" className='label'>Probation date:</label>
                                                <DatePicker name='probationDate' autoComplete='false' className='input' dateFormat="dd/MM/yyyy" selected={employeDetail?.probationDate} onChange={(value) => handleDate(value, 'probationDate')} />
                                            </div>
                                        </div>
                                        <br />
                                        {leaveDetails &&
                                            <div className='px-4'>
                                                <LeavesCalculation
                                                    employeDetail={employeDetail}
                                                    control={control}
                                                    employeeType={employeDetail.type_of_employee}
                                                    onChangeText={onChangeLeaveCalculation}
                                                    type={employeDetail.employmentType}
                                                />
                                            </div>}
                                        {employeDetail.type_of_employee === 'employee' &&
                                            <div className='px-5 mt-10'>
                                                <div>
                                                    <h1 className='mt-4 text-2xl font-semibold'>Statutory Components</h1>
                                                    <small>Enable the necessary benefits and tax applicable for this employee.</small>
                                                </div>
                                                <div className=' space-y-4'>
                                                    <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
                                                        <div className=" mt-4">
                                                            <div className=' space-x-2'>
                                                                <input type="checkbox" id='isEpf' name='isEpf' onChange={handleChangeStatutoryComponent} />
                                                                <label htmlFor='isEpf' className=' cursor-pointer'>Employees' Provident Fund</label>
                                                            </div>
                                                            {employeDetail.statutory.epf.isEpf &&
                                                                <div className='border-b border-gray-200 pb-4'>
                                                                    <div className=" grid grid-cols-2 gap-4 leaveAllot-section">
                                                                        <div className="">
                                                                            <label htmlFor="total_leave" className='label'>PF Account Number:</label>
                                                                            <input type="text" name='pf_account_number' value={employeDetail.statutory.epf.pf_account_number} onChange={(e) => handleChangeStatutoryForm(e, 'epf')} className='input disabled:cursor-not-allowed' placeholder='AA/AAA/0000000/000/0000000' />
                                                                        </div>
                                                                        <div className="">
                                                                            <label htmlFor="total_leave" className='label'>UAN:</label>
                                                                            <input type="text" name='pf_uan_number' value={employeDetail.statutory.epf.pf_uan_number} onChange={(e) => handleChangeStatutoryForm(e, 'epf')} className='input disabled:cursor-not-allowed' placeholder='000000000000' />
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
                                                        <div className=" mt-4">
                                                            <label htmlFor="" className=' space-x-2 cursor-pointer'>
                                                                <input type="checkbox" name='isEsi' id='isEsi' onChange={handleChangeStatutoryComponent} />
                                                                <label htmlFor='isEsi' className=''>Employees' State Insurance</label>
                                                            </label>
                                                            {employeDetail?.statutory?.esi?.isEsi &&
                                                                <div className='border-b border-gray-200 pb-4'>
                                                                    <div className=" grid grid-cols-2 gap-4 leaveAllot-section">
                                                                        <div className="">
                                                                            <label htmlFor="total_leave" className='label'>ESI Insurance Number:</label>
                                                                            <input type="text" name='esi_number' value={employeDetail.statutory.esi.esi_number} onChange={(e) => handleChangeStatutoryForm(e, 'esi')} className='input disabled:cursor-not-allowed' placeholder='000000000000' />
                                                                        </div>
                                                                    </div>
                                                                    <label htmlFor="" className=' space-x-2 mt-4 block'>
                                                                        <small className=' text-gray-500'>Note: ESI deductions will be made only if the employee’s monthly salary is less than or equal to ₹21,000 </small>
                                                                    </label>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
                                                        <div className=" mt-4">
                                                            <label htmlFor="" className=' space-x-2 cursor-pointer'>
                                                                <input type="checkbox" name='isPt' id='isPt' checked={employeDetail.statutory.isPt} onChange={(e) => handleChangeStatutoryCheckbox(e, 'isPt')} />
                                                                <label htmlFor='isPt' className=''>Professional Tax</label>
                                                            </label>
                                                            <label htmlFor="" className=' space-x-2 block'>
                                                                <small className=' text-gray-500'>
                                                                    Note: Professional Tax deductions <br />
                                                                    Male employee if salary between &#8377; 7,500 - &#8377; 10,000
                                                                    - (&#8377;175) will be deducted if salary greater then &#8377; 10,000 - (&#8377;200) will be deducted <br />
                                                                    Female employee if salary between &#8377; 7,500 - &#8377; 25,000
                                                                    - (&#8377;0) will be deducted if salary greater then &#8377; 25,001 - (&#8377;200) will be deducted <br />
                                                                </small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                    {isLoading ? <div className=' w-14 h-14 mx-auto my-8'>
                                        <img src={spinner} alt="spinner" className='w-full h-full' />
                                    </div> : <div className=" text-center my-8">
                                        <button type='submit' className=' bg-[color:var(--color1)] hover:bg-[color:var(--color12)] text-white py-3 px-20 rounded-full'>Submit</button>
                                    </div>}
                                </>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <Modals show={isShowingModal}>
                <div>
                    <p className=' roboto'>Your Data is Sucessfully Stored </p>
                    <div className=" mt-12">
                        <a href={"/employe-details"} className='  bg-[color:var(--color1)] text-white px-5 py-2 rounded'>Ok</a>
                    </div>
                </div>
            </Modals>
        </Layout >
    )
}

export default AddEmployeeDetails