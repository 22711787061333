import React, { useRef, useState } from 'react'
import { getUserDocumentsList, postEmployeeDetailsDelete, userDocumentAdd, userDocumentRemove } from '../../api/employeeDetails'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Layout from '../../components/layout/Layout'
import { EyeIcon, PencilAltIcon, TrashIcon, XCircleIcon, XIcon } from '@heroicons/react/solid';
import Modal from '@mui/material/Modal';
import Modals from '../../components/modal/Modals';
import spinner from '../../assets/images/spinner.gif'
import ZoomImage from '../../components/ZoomImage';
import { downloadUserReport } from '../../api'
import EmployeeTab from './EmployeeTab';
import useGet from '../../hooks/read/useGet';
import { useQueryClient } from "@tanstack/react-query";
import Pagination from '../../components/pagination';
import PrimaryLoader from '../../components/common/PrimaryLoader';


function EmployeeDetails() {
   const queryClient = useQueryClient()
   const [searchParams, setSearchParams] = useSearchParams();
   const initialEmployeeState = {
      limit: 50,
      search_query: searchParams.get('search_query') || '',
      page_number: searchParams.get('page_number') || 0,
      screen_name: 'employee'
   }
   const navigate = useNavigate()
   const eduFileRef = useRef(null)
   const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false)
   const [employeeId, setEmployeeId] = useState('')
   const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false)
   const [documents, setDocuments] = useState([])
   const handleDelete = () => {
      postEmployeeDetailsDelete(employeeId)
         .then((res) => {
            if (res.status === 200) {
               setIsConfirmDeleteModal(false)
               queryClient.invalidateQueries('employeelist')
            }
         })
         .catch(err => {
            console.error(err)
         })

   }
   const { list: employeeList, paramsObject, setGetListParams } = useGet({
      url: 'employeelist',
      initialData: initialEmployeeState
   })

   const [isFetchingDocuments, setIsFetchingDocuments] = useState(false)
   const [removeDocumentConfirmModal, setRemoveDocumentConfirmModal] = useState(false)
   const [userId, setUserId] = useState('')
   const [doumentId, setDocumentId] = useState('')
   const [docType, setDocType] = useState('')
   const [isUploadingFile, setIsuploadingFile] = useState(false)
   const [employeeName,setEmployeeName] = useState('')
   const handleFetchUserDocuments = (id,name) => {
      setIsOpenDocumentModal(true)
      setIsFetchingDocuments(true)
      setEmployeeName(name)
      setUserId(id)
      getUserDocumentsList(id)
         .then((res) => {
            if (res.status === 200) {
               setDocuments(res.data.data)
               // setIsOpenDocumentModal(true)
            }
         })
         .catch((err) => {
            console.log(err)
         })
         .finally(() => {
            setIsFetchingDocuments(false)
         })
   }
   const handleRemoveDocumentConfirmModal = (id) => {
      setDocumentId(id)
      setIsOpenDocumentModal(false)
      setRemoveDocumentConfirmModal(true)
   }

   const handleDeleteDocument = () => {
      userDocumentRemove(doumentId)
         .then((res) => {
            if (res.status === 200) {
               setRemoveDocumentConfirmModal(false)
               setIsOpenDocumentModal(true)
               handleFetchUserDocuments(userId)
            }
            console.log(res)
         })
         .catch((err) => {
            console.log(err)
         })
   }

   const handleAddEduDoc = () => {
      if (!docType || docType === '' || docType === undefined) return alert('Please select document type first')
      eduFileRef.current.click()
   }
   const handleFileUpload = (e) => {
      setIsuploadingFile(true)
      let file = e.target.files
      userDocumentAdd({ userId, file, docType })
         .then((res) => {
            if (res.status === 200) {
               setIsuploadingFile(false)
               setIsOpenDocumentModal(true)
               handleFetchUserDocuments(userId)
            }
         })
         .catch((err) => {
            setIsuploadingFile(false)
            alert('Something went wrong while uploading please try again')
         })
   }
   const handleDownloadReport = () => {
      downloadUserReport()
         .then((res) => {
            // console.log(res)
            // create file link in browser's memory
            const href = URL.createObjectURL(res.data);

            let fileName = `User_Report.pdf`
            // create "a" HTLM element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
         })
         .catch(() => { })
   }
   const handleNavigateToEmployeeDetail = (id, username) => {
      navigate(`${id}/${username}`)
   }

   const handleSearch = (e) => {
      let value = e.target.value
      let data = {
         ...paramsObject,
         search_query: value,
      }
      setGetListParams(data)
      setSearchParams(data)
   }
   const handlePageClick = (val) => {
      let data = {
         ...paramsObject,
         page_number: val.selected
      }
      setGetListParams(data)
      setSearchParams(data)
   }
   return (
      <Layout>
         <div className="mb-12">
            <div className=" container">
               <div className="space-x-4 px-4 py-4 text-end">
                  <Link to={"/add-employe-details"} className=" bg-[color:var(--color1)] inline-block text-white rounded px-4 py-4">Add User Details</Link>
                  <button type='button' onClick={handleDownloadReport} className=" bg-[color:var(--color1)] text-white rounded px-4 py-4">Download Report</button>
               </div>
            </div>
            <EmployeeTab />
            <div className=' px-4 pt-4 w-[40%]'>
               <input type="search" value={paramsObject.search_query} placeholder='Search By employee name...' name='search_query' className='input' onChange={handleSearch} />
            </div>
            {employeeList.isLoading
               ? <div className=' flex items-center justify-center mt-10'> <PrimaryLoader /></div>
               :
               <>
                  {employeeList.data?.data.length <= 0 ? <div className='text-center'><p className=' text-gray-700 text-3xl mt-4'>No Employee/Consultant Found</p></div> : <div class="max-w-full overflow-x-auto px-4 mt-8">
                     <table class="table-auto w-full">
                        <thead>
                           <tr class=" bg-[color:var(--color1)] text-center">
                              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Name</th>
                              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Username</th>
                              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Email</th>
                              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">User Type</th>
                              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Documents</th>
                              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Leaves</th>
                              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4border-r border-transparent">Modify</th>
                           </tr>
                        </thead>
                        <tbody>
                           {
                              employeeList.data?.data.map((data, index) => {
                                 const { employee_name, username, email, id, type_of_employee } = data
                                 return <tr key={index} onClick={() => handleNavigateToEmployeeDetail(id, employee_name)} className='cursor-pointer'>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{employee_name}</td>
                                    <td class=" text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{username}</td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{email}</td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{type_of_employee}</td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                       <div className='flex items-center justify-center w-full' onClick={(e) => e.stopPropagation()}>
                                          <button type='button' onClick={() => handleFetchUserDocuments(id,employee_name)} className='bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center'><EyeIcon className='w-6 h-6' /> <span>View</span></button>
                                       </div>
                                    </td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                       <div className='flex items-center justify-center w-full'>
                                          <Link onClick={(e) => e.stopPropagation()} to={`/leaves/${id}`} className='bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center'><EyeIcon className='w-6 h-6' /> <span>View</span></Link>
                                       </div>
                                    </td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-r border-[#E8E8E8]">
                                       <div className=" flex justify-center space-x-1">
                                          <Link onClick={(e) => e.stopPropagation()} to={{
                                             pathname: `/edit-employe-details/${type_of_employee}/${id}`,
                                          }} className=" ">
                                             <PencilAltIcon className=' text-gray-600 w-6 hover:text-blue-600' />
                                          </Link>
                                          {/*  onClick={(e) => {e.stopPropagation();setIsConfirmDeleteModal(true); setEmployeeId(id) }} */}
                                          <div className=""  onClick={(e) => {e.stopPropagation() }}>
                                             <TrashIcon className=' text-gray-600 w-6 hover:text-orange-600 cursor-pointer' onClick={() => { setIsConfirmDeleteModal(true); setEmployeeId(id); console.log(';'); }} />
                                          </div>
                                       </div>
                                    </td>
                                 </tr>
                              })
                           }
                        </tbody>
                     </table>
                     <div className='px-5 mt-5'>
                        <Pagination
                           currentPage={+paramsObject.page_number}
                           lengthofItems={employeeList.data?.total_count}
                           limit={paramsObject.limit}
                           onPageChange={handlePageClick}
                        />
                     </div>
                  </div>}
               </>}
         </div>
         {isConfirmDeleteModal && 
         <div className='fixed w-full h-full bg-black/20 z-50 top-0 left-0'>
            <div className='bg-white z-50 relative w-1/2 mx-auto mt-10 rounded-md py-4'>
               <p className=' roboto text-center font-bold text-2xl text-gray-700'>Are you sure you want to delete?</p>
               <div className=" mt-12 space-x-3 text-center ">
                  <button className='px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800' onClick={handleDelete}>Yes</button>
                  <button className='px-4 py-2 rounded-md font-semibold  border' onClick={() => setIsConfirmDeleteModal(false)}>Cancel</button>
               </div>
            </div>
         </div>
          }
        
         <Modal open={isOpenDocumentModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
            <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
               <div className='document-wrapper px-4 divide-y'>
                  <div className="document-head py-4">
                     <div className='flex items-center justify-between'>
                        <h3 className=' text-gray-700 text-xl font-semibold'>Documents</h3>
                        <div className=' w-6 h-6 cursor-pointer' onClick={() => setIsOpenDocumentModal(false)}>
                           <XIcon className=' text-black w-full h-full' />
                        </div>
                     </div>
                  </div>
                  <div className="document-body py-4 max-h-96 overflow-auto">
                     {isFetchingDocuments
                        ? <div className=' flex items-center justify-center my-10'> <PrimaryLoader /></div>
                        :
                        <>
                           {documents?.length <= 0 ? <div className='text-gray-700 my-5 text-center text-2xl'><h3>No Documents To Show</h3></div> : <div className='grid grid-cols-4 gap-3'>
                              {documents?.map((document, idx) => {
                                 const { file_name, media_id, media_type, document_media_type } = document
                                 return <>
                                    {
                                       document_media_type === 'pdf' 
                                       ? 
                                          <div className='text-center h-40 flex items-center justify-center  rounded bg-gray-400 text-gray-700 relative'>
                                             <div className='w-6 h-6 absolute cursor-pointer right-2 top-2 z-20 ease-in-out duration-300 hover:scale-125' onClick={() => handleRemoveDocumentConfirmModal(media_id)}><XCircleIcon className=' w-full h-full' /></div>
                                             <a href={media_type} target="_blank" rel="noopener noreferrer" className='px-2 h-full w-full flex items-center justify-center'>
                                                View PDF
                                             </a> 
                                          </div>
                                       :
                                          <div key={idx} className='w-full h-40 border-2 border-black relative overflow-hidden rounded'>
                                             <div className='w-6 h-6 absolute cursor-pointer right-2 top-2 z-20 ease-in-out duration-300 hover:scale-125' onClick={() => handleRemoveDocumentConfirmModal(media_id)}><XCircleIcon className=' w-full h-full' /></div>
                                             <ZoomImage><img src={media_type} alt="documents" className='w-full h-full object-cover object-center' /></ZoomImage>
                                          </div>
                                    }
                                 </>

                              })}
                           </div>}
                        </>
                     }
                  </div>
                  <div className="document-foot space-x-4 py-4">
                     {isUploadingFile ? <div className=' w-8 h-8 mx-auto'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className="grid grid-cols-4 gap-3">
                        <input ref={eduFileRef} type="file" className='hidden' multiple onChange={handleFileUpload} />
                        <select name="documentType" className='input' onChange={(e) => setDocType(e.target.value)}>
                           <option value="">-- Select Document Type --</option>
                           <option value="EDUCTIONAL">Education</option>
                           <option value="WORK">Work</option>
                        </select>
                        <button className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white' onClick={handleAddEduDoc}>Add Document</button>
                        <button className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={() => setIsOpenDocumentModal(false)}>Cancel</button>
                     </div>}
                  </div>
               </div>
            </div>
         </Modal>
         <Modal open={removeDocumentConfirmModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
            <div className='bg-white max-w-lg mx-auto mt-10 py-4'>
               <p className=' roboto text-center font-bold text-2xl text-gray-700'>Are you sure you want to delete this document?</p>
               <div className=" mt-12 space-x-3 text-center ">
                  <button className='px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800' onClick={handleDeleteDocument}>Delete</button>
                  <button className='px-4 py-2 rounded-md font-semibold  border' onClick={() => { setRemoveDocumentConfirmModal(false); setIsOpenDocumentModal(true) }}>Cancel</button>
               </div>
            </div>
         </Modal>
      </Layout>
   )
}

export default EmployeeDetails




 // <Modals open={isConfirmDeleteModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
         // </Modals>