import axios from "axios";
import { TOKEN_NAME, config } from "../config";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const loginUser = async (data) => {
    var res = await axios({ method: "POST", url: `${config.apiurl}loginuser`, data: data })
    return await res
};
export const resetPassword = async (id,password) => {
    let token = cookies.get(TOKEN_NAME);
    var form_data = new FormData();    
    form_data.append('id', id);
    form_data.append('password', password);
    var res = await axios({ method: "POST", url: `${config.apiurl}passwordreset`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};




