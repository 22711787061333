import { useMutation } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { TOKEN_NAME } from "../../config";
import axios from "../../api/axios";
const cookies = new Cookies();
const usePut = ({url,onSuccess,onError}) => {
  const handleAuth = useMutation({
    mutationFn: (data) => handleFormSubmit(data),
    onSuccess: (data) => {
      onSuccess?.(data)
    },
    onError: (err) => {
      onError?.(err)
    },
  })
  const handleFormSubmit = async (getFormData) => {
    let token = cookies.get(TOKEN_NAME);
    let fd = new FormData()
    for (var key in getFormData) {
      if (Array.isArray(getFormData[key])) {
        fd.append(key, JSON.stringify(getFormData[key]));
      } else {
        fd.append(key, getFormData[key]);
      }
    }

    const response = await axios({
      method: 'POST',
      url: `/${url}`,
      data: fd,
      headers: { Authorization: token ? `Bearer ${token}` : '', }
    })
    return response
  }
  return { handleAuth }
}

export default usePut