import React, { useEffect, useState } from 'react'
import { BriefcaseIcon, CalendarIcon, CashIcon, ClipboardIcon, ColorSwatchIcon, DocumentTextIcon, PresentationChartBarIcon, SwitchHorizontalIcon, IdentificationIcon, BookOpenIcon } from '@heroicons/react/solid'
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import NavbarDropdown from './dropdown/navbarDropdown';
import { ROLE } from '../config';

const cookies = new Cookies

function Sidebar({ show }) {
  const [role, setRole] = useState('')
  useEffect(() => {
    setRole(cookies.get(ROLE))
  }, [])

  const [moreMenuOpen, setMoreMenuOpen] = useState(null)
  const handleOpenMoreMenu = (value) => {
    setMoreMenuOpen(value)
  }

  const handleCloseMoreMenu = () => {
    setMoreMenuOpen(false)
  }
  const SHIFT = [
    {
      name: 'Shifts Creation',
      slug: '/shifts',
    },
    {
      name: 'Employee Shift Mapping',
      slug: '/employee-shift-mapping',
    },
    {
      name: 'Shifts Rotation',
      slug: '/shift-rotation',
    },
    {
      name: 'Settings',
      slug: '/shift-settings',
    },
  ]
  const SALARY = [
    {
      name: 'Salary Creation',
      slug: '/salary',
    },
    {
      name: 'ESIC',
      slug: '/salary/esic',
    },
    {
      name: 'PF',
      slug: '/salary/pf',
    },
  ]
  return (
    <>
      <div className={`bg-[color:var(--color1)] border-r border-[color:var(--color1)] w-64 h-screen p-4 overflow-auto fixed ${show ? 'active' : ''}`}>
        <div >
          <div>
            <p className=' text-center  text-4xl text-white font-bold mb-10 roboto'><Link to={"/"}>Ambrosian</Link></p>
          </div>
          <div className=' divide-y divide-white'>
            {(role === 'admin' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/employe-details'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <BriefcaseIcon className='w-5' />
                <span className=' text-sm'>User Details</span>
              </Link>
            </div>}
            {role === 'employee' || role === 'consultant' ? <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/leave-application'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <DocumentTextIcon className=' w-6' />
                <span className=' text-sm'>Leave Application</span>
              </Link>
            </div> : null}
            {/* {(role === 'admin' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/appointment'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <ClipboardIcon className='w-5' />
                <span className=' text-sm'>Appointment Letter</span>
              </Link>
            </div>} */}
            {(role === 'admin' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/leave-applied'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <ClipboardIcon className='w-5' />
                <span className=' text-sm'>Leaves Applied</span>
              </Link>
            </div>}
            {(role === 'employee' || role === 'consultant') && <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/leaves'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <ClipboardIcon className='w-5' />
                <span className=' text-sm'>Leaves Applied</span>
              </Link>
            </div>}
            {(role === 'admin' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/salary'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <CashIcon className='w-5' />
                <span className=' text-sm'>Salary</span>
              </Link>
            </div>}
            {(role === 'admin' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/shifts'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <SwitchHorizontalIcon className='w-5' />
                <span className=' text-sm'>Shifts</span>
              </Link>
            </div>}
            {(role === 'employee' || role === 'consultant') && <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/shift-details'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <SwitchHorizontalIcon className='w-5' />
                <span className=' text-sm'>Shifts Details</span>
              </Link>
            </div>}
            {(role === 'admin' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/employment-management'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <ColorSwatchIcon className='w-5' />
                <span className=' text-sm'>Employment</span>
              </Link>
            </div>}
            {(role === 'admin' || role === 'hr' || role === 'employee' || role === 'consultant') && <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/public-holidays'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <CalendarIcon className='w-5' />
                <span className=' text-sm'>Public Holidays</span>
              </Link>
            </div>}
            {role === 'admin' && <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/attendance'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <PresentationChartBarIcon className='w-5' />
                <span className=' text-sm'>Attendance</span>
              </Link>
            </div>}
            {role === 'admin' &&  <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/web-check-ins'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <IdentificationIcon className='w-5'/>
                <span className=' text-sm'>Web Check-ins</span>
              </Link>
            </div>}
            {role === 'admin' &&  <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/hand-book'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <BookOpenIcon className='w-5'/>
                <span className=' text-sm'>Hand Books</span>
              </Link>
            </div>}
            {(role === 'employee' || role === 'consultant') &&  <div className=" text-white text-lg roboto text-center py-2">
              <Link to={'/hand-book'} className=' flex items-center px-2 py-2 rounded space-x-3 hover:bg-[color:var(--color10)]'>
                <BookOpenIcon className='w-5'/>
                <span className=' text-sm'>Hand Books</span>
              </Link>
            </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar