import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { PencilAltIcon, XIcon } from '@heroicons/react/solid'
import { Modal } from '@mui/material'

function Department() {
  const [openDepartmentModal,setOpenDepartmentModal] = useState(false)
  const [openDepartmentAddModal,setOpenDepartmentAddModal] = useState(false)
  const handleEditDepartment = () => {}
  return (
    <Layout>
      <div>

      </div>
      <div class="max-w-full overflow-x-auto px-4 mt-8">
        <table class="table-auto w-full">
          <thead>
            <tr class=" bg-[color:var(--color1)] text-center">
              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.no</th>
              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Department Name</th>
              <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4border-r border-transparent">Modify</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">01</td>
              <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">Information Technology</td>
              <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                <div className='text-center mx-auto w-6 h-6 cursor-pointer' onClick={() => setOpenDepartmentModal(true)}>
                  <PencilAltIcon className=' text-gray-600 w-full h-full hover:text-blue-600' />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal open={openDepartmentModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
        <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
          <div className='document-wrapper px-4 divide-y'>
            <div className="document-head py-4">
              <div className='flex items-center justify-between'>
                <h3 className=' text-gray-700 text-3xl font-semibold'>Edit Department</h3>
                <div className=' w-6 h-6 cursor-pointer' onClick={() => setOpenDepartmentModal(false)}>
                  <XIcon className=' text-black w-full h-full' />
                </div>
              </div>
            </div>
            <div className="document-body py-4 max-h-96 overflow-auto">
            </div>
            <div className="document-foot space-x-4 py-4">
              <div className="grid grid-cols-4 gap-3">
                <button className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white' onClick={handleEditDepartment}>Edit Department</button>
                <button className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={() => setOpenDepartmentModal(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default Department