import React, { useState } from 'react'
import Layout from '../../../components/layout/Layout'
import { PlusIcon } from '@heroicons/react/solid'
import ShiftMappingDrawer from './ShiftMappingDrawer'
import useReadWithGet from '../../../hooks/read/useReadWithGetMethod'

const ShiftMapping = () => {
    const initialShiftsState = {
        limit: 100,
        searchQuery: '',
        page_number: 0,
    }
    const [shiftData, setShiftData] = useState(initialShiftsState)
    const [isOpenShiftMapping, setIsOpenShiftMapping] = useState(false)
    const handleOpenShiftMap = () => {
        setIsOpenShiftMapping(true)
    }
    const { list, paramsObject, setGetListParams } = useReadWithGet({
        url: "employeelist",
        initialData: shiftData,
    })
    return (
        <Layout>
            <div className='p-5'>
                <div>
                    <button onClick={handleOpenShiftMap} className=' rounded-md bg-color1 px-8 py-2 text-white'>
                        <div className='flex items-center justify-center space-x-2'>
                            <PlusIcon className='w-6 h-6' />
                            <span className=' font-semibold'>Assign Shift</span>
                        </div>
                    </button>
                </div>
            </div>
            <div class="max-w-full overflow-x-auto px-4">
                <table class="table-auto w-full">
                    <thead>
                        <tr class=" bg-[color:var(--color1)] text-center">
                            <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Employee</th>
                            <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">{`Shift(s)`}</th>
                            <th class="w-1/6  text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4border-r border-transparent">Modified On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list.data?.data.map((data, index) => {
                                const { employee_name, username, email, id, type_of_employee } = data
                                return <tr key={index} className='cursor-pointer'>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{employee_name}</td>
                                    <td class=" text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{username}</td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{email}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            <ShiftMappingDrawer open={isOpenShiftMapping} onClose={() => setIsOpenShiftMapping(false)} />
        </Layout>
    )
}

export default ShiftMapping