import React from 'react'
import Layout from '../../components/layout/Layout'

function LeavesApplied() {
  return (
    <Layout>
        
    </Layout>
  )
}

export default LeavesApplied