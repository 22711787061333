// production "https://api.ambrosianresources.in/"
// development https://api-ambrosian.renderly.in/

export const ENVIONMENT = 'production' //production | development
export const PROJECT_NAME = 'Ambrosian'
export const PROJECT_META_NAME = 'ambrosian'
export const config = {
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTg3NTAwMzUsIm5iZiI6MTY1ODc1MDA0NSwiZXhwIjoxNjU5NjE0MDM1LCJ1c2VybmFtZSI6ImFkbWluaXN0cmF0b3IiLCJpZCI6dHJ1ZSwiaXNzIjoiVGVhbU5nYWdlIiwiYXVkIjoiVGVhbU5nYWdlIn0.9iciZeKjkEIZRB7Oe0UEMZM46O_2fOaRki7LbnmTmhw',
    role: 'admin',
    role: 'member',
    apiurl: ENVIONMENT === 'production' ? "https://api.ambrosianresources.in/" : "https://api-ambrosian.renderly.in/"
    // apiurl: "http://local.ambrosian.in/"
}


export const TOKEN_NAME = ENVIONMENT === 'development' ? `${PROJECT_META_NAME}_dev_token` :  `${PROJECT_META_NAME}_prod_token`
export const ROLE = ENVIONMENT === 'development' ? `${PROJECT_META_NAME}_dev_role` :  `${PROJECT_META_NAME}_prod_role`
export const USERNAME = ENVIONMENT === 'development' ? `${PROJECT_META_NAME}_dev_username` :  `${PROJECT_META_NAME}_prod_username`

export const TAX = 175;
export const ABOVE_LIMIT_TAX = 200;
export const EXEED_MONTH_LIMIT = 300;
export const TDS = 10;
export const PERCENTILE = 100;
export const GST = "";

export const MIN_LEAVE_DATE_FOR_PL = 15;
export const MIN_LEAVE_DATE_FOR_CL = 5;

export const DISCRETIONAL_LEAVE_YEAR_IGNORE = 2022 


export const FULL_TIME_EMPLOYEE = 90
export const PART_TIME_EMPLOYEE = 45
export const ON_SIDE = 30
export const REMOTE = 30

export const NOTICE_PERIOD_EMPLOYEE = 45
export const NOTICE_PERIOD_CONSULTANT = 30

export const FULL_TIME_EMPLOYEE_LEAVES = {
    pl: 15,
    sl: 3,
    cl: 3
} 
export const PART_TIME_EMPLOYEE_LEAVES = {
    pl: 10,
    sl: 2,
    cl: 3
} 
export const ON_SITE_EMPLOYEE_LEAVES = {
    ol: 10,
} 
export const REMOTE_EMPLOYEE_LEAVES = {
    holiday: 4,
} 
