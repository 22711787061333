import React, { useState } from 'react'
import Modals from '../../components/modal/Modals';
import Moment from 'react-moment';
import { setConfirmationLetter } from '../../api/employeeDetails';
import spinner from '../../assets/images/spinner.gif'
let IS_REQUIRED = true
function ConfirmationLetter({ data, onClose }) {
    const initialData =  {
        ...data,
        subject: 'Confirmation Letter',
        for_name:'Ambrosian Well-being & Engagement Resources Pvt. Ltd.',
        capacity: data?.designation
    }
    const [isLoading, setIsLoading] = useState(false)
    const [appointmentLetterData, setAppointmentLetterData] = useState(initialData)
    const [isShowingModal, setIsShowingModal] = useState(false)
    const date = new Date();
    const handleInput = (e) => {
        let value = e.target.value
        setAppointmentLetterData({
            ...appointmentLetterData,
            [e.target.name]: value
        })
    }

    const handleAppointmentLetter = (e) => {
        setIsLoading(true)
        e.preventDefault()
        setConfirmationLetter(appointmentLetterData)
            .then((res) => {
                if (res.status === 200) {
                    setIsShowingModal(true)
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                alert('Something went wrong try again after some time')
            })
    }
    return (
        <>
            <div className=" bg-[color:var(--color3)]">
                <div className="container p-4">
                    <div className="my-4">
                        <label htmlFor="date" className='label'>Date:</label>
                        <span className=' text-xl font-semibold text-gray-700 inline-block ml-3'><Moment format="D MMM YYYY">{date}</Moment></span>
                    </div>
                    <form method='POST' onSubmit={handleAppointmentLetter}>
                        <div className=" grid grid-cols-2 gap-4">
                            <div className="">
                                <label htmlFor="salary" className='label'>Salary:</label>
                                <input type="tel" name='gross_salary' value={appointmentLetterData.gross_salary} onChange={handleInput} className='input' placeholder='Enter salary' />
                            </div>
                            <div className="">
                                <label htmlFor="designation" className='label'>Designation:</label>
                                <input type="text" name='designation' className='input' value={appointmentLetterData?.designation} onChange={handleInput} placeholder='Designation' />
                            </div>
                            <div className="">
                                <label htmlFor="department" className='label'>Department:</label>
                                <input type="text" name='department' className='input' value={appointmentLetterData?.department} onChange={handleInput} placeholder='Department' />
                            </div>
                            <div className="">
                                <label htmlFor="date" className='label'>Date Of Confirmation:<span className='mandatory'>*</span></label>
                                <input type="date" required={IS_REQUIRED} name='confirmation' value={appointmentLetterData.confirmation} onChange={handleInput} className='input' />
                            </div>
                            <div className=" hidden">
                                <label htmlFor="capacity" className='label'>Capacity:</label>
                                <input type="text" name='capacity' value={appointmentLetterData.designation} onChange={handleInput} className='input' placeholder='Enter capacity' />
                            </div>
                            <div className="">
                                <label htmlFor="for_name" className='label'>For Name:</label>
                                <input type="text" name='for_name'  className={`input`} value={appointmentLetterData.for_name}  onChange={handleInput} placeholder='For Name' />
                            </div>
                            <div className="">
                                <label htmlFor="name" className='label'>Name:</label>
                                <input type="text" name='name' className='input' value={appointmentLetterData?.employee_name} onChange={handleInput} placeholder='Name' />
                            </div>
                            <div className="">
                                <label htmlFor="Subject" className='label'>Subject:</label>
                                <input type="text" disabled readOnly name='subject' className='input disabled:cursor-not-allowed' value="Confirmation Letter" onChange={handleInput} placeholder='Enter Subject' />
                            </div>
                            <div className="">
                                <label htmlFor="Appointment letter date" className='label'>Appointment Letter Date:<span className='mandatory'>*</span></label>
                                <input type="date" name='appointment_letter_date' required={IS_REQUIRED} className='input' value={appointmentLetterData?.appointment_letter_date} onChange={handleInput} />
                            </div>
                        </div>
                        <div className="">
                            {isLoading ? <div className=' w-10 h-10 mx-auto mt-8'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className=" space-x-4 text-center my-8">
                                <button type='submit' className=' bg-[color:var(--color1)] text-white  py-3 px-20 rounded-full'>Submit</button>
                                <button onClick={onClose} className=' bg-[color:var(--color2)] text-[color:var(--color1)] border-2 border-gray-400 py-3 px-20 rounded-full'>Cancel</button>
                            </div>}
                        </div>
                    </form>
                </div>
            </div>
            <Modals show={isShowingModal}>
                <div>
                    <p className=' roboto'>Your Data is Sucessfully Stored </p>
                    <div className=" mt-12">
                        <button type='button' onClick={onClose} className='  bg-[color:var(--color1)] text-white px-5 py-2 rounded'>Ok</button>
                    </div>
                </div>
            </Modals>
        </>
    )
}

export default ConfirmationLetter