import { LogoutIcon, MenuIcon, UserIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import profile from '../assets/images/profile.jpg'

import "./header.css";
import Dropdown from './dropdown/Dropdown';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { ROLE, TOKEN_NAME, USERNAME } from '../config';






const cookies = new Cookies
function Header({onClick}) {
    const [isShowDropDown,setIsShowDropDown] = useState(false)
    const navigate=useNavigate();

    const handleLogout =()=>{
        cookies.remove(TOKEN_NAME)
        cookies.remove(ROLE)
        cookies.remove(USERNAME)
        // localStorage.clear();
        navigate('/login');
    };
    const [name,setName] = useState('')
    useEffect(() => {
        setName(cookies.get("_am_name"))
  },[])
    
    return (
       
            <>
            <div className=" text-white bg-gray-500 h-16 px-4 shadow sticky top-0 mb-4 z-40">
                <div className=" flex items-center justify-between h-full">
                    <div className="cursor-pointer toggle" onClick={onClick}>
                        <MenuIcon className='w-8 text-white ' />
                    </div>
                    <div className="relative">
                        <div onClick={() => setIsShowDropDown(!isShowDropDown)} className=" cursor-pointer flex items-center">
                            <img src={profile} alt="profile" className=' h-10 rounded-full' />
                            <p className=' roboto ml-4 font-semibold'>{name}</p>
                        </div>
                        {
                            isShowDropDown?<Dropdown show={isShowDropDown} className={' top-12 left-[-4rem] w-[7.5rem] bg-[color:var(--color1)] px-2 py-3 rounded-l'}>
                            <div onClick={handleLogout}>
                                <ul className=' space-y-4'>
                                    <li className='flex text-white cursor-pointer'>
                                        <LogoutIcon className=' w-6' />
                                        <span className=' ml-3'>Logout</span>
                                    </li>
                                    {/* <li className='flex text-gray-700'>
                                        <UserIcon className='w-6'/>
                                        <span>Edit Profile</span>
                                    </li> */}
                                </ul>
                            </div>
                        </Dropdown>:null
                        }
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header