import React from 'react'
import SpinnerGif from '../../assets/images/spinner.gif'

const Spinner = ({ fullWidth }) => {
    return (
        <>
            {fullWidth
                ? <div className='fixed w-full h-full top-0 left-0 bg-[#ffffff6e] z-40'>
                    <div className='flex items-center justify-center w-full h-full'>
                        <div className='w-20 h-20'>
                            <img src={SpinnerGif} alt='spinner' className='w-full h-full' />
                        </div>
                    </div>
                </div>
                : ''}
        </>
    )
}

export default Spinner