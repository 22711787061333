import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout/Layout'
import { getAppoinmentLetterList, userDocumentRemove } from '../../api/employeeDetails';
import { Modal } from '@mui/material';
import AppointmentLetter from './AppointmentLetter';
import { DownloadIcon, PlusIcon, TrashIcon, XIcon } from '@heroicons/react/solid';
import ConfirmationLetter from './ConfirmationLetter';
import spinner from '../../assets/images/spinner.gif'
import Modals from '../../components/modal/Modals';


function Appointment() {
   const [isLoading, setIsLoading] = useState(false)
   const [employeAppointment, setemployeAppointment] = useState([])
   const [isOpenModal, setIsOpenModal] = useState(false)
   const [singleAppointmentData, setSingleAppointmentData] = useState({})
   const [letterType, setLetterType] = useState('')
   const [doumentId, setDocumentId] = useState('')
   const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false)
   useEffect(() => {
      fetchAppointmentLetter()
   }, [])

   const fetchAppointmentLetter = () => {
      setIsLoading(true)
      getAppoinmentLetterList()
         .then((res) => {
            if (res.status === 200) {
               setemployeAppointment(res.data)
               setIsLoading(false)
            }
         })
         .catch((err) => {
            setIsLoading(false)
            console.log(err)
         })
   }

   const handleAppointmentLetter = (id, letterType) => {
      console.log(employeAppointment.find(data => data.id === id))
      setSingleAppointmentData(employeAppointment.find(data => data.id === id))
      setIsOpenModal(true)
      setLetterType(letterType)
   }

   const handleCloseModal = () => {
      setIsOpenModal(false)
      fetchAppointmentLetter()
   }
   const handleRemoveDocumentConfirmModal = (id) => {
      setDocumentId(id)
      setIsConfirmDeleteModal(true)
   }

   const handleDeleteDocument = () => {
      userDocumentRemove(doumentId)
         .then((res) => {
            if (res.status === 200) {
               setIsConfirmDeleteModal(false)
               fetchAppointmentLetter()
            }
         })
         .catch((err) => {
            console.log(err)
         })
   }
   return (
      <Layout>
         {isLoading ? <div className=' w-16 h-16 mx-auto mt-8'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className=" bg-[color:var(--color3)]">
            <div class="max-w-full overflow-x-auto px-4 mt-8">
               <table class="table-auto w-full">
                  <thead>
                     <tr class=" bg-[color:var(--color1)] text-center">
                        <th class=" w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Employee Name</th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Email</th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Appointment Letter</th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Confirmation Letter</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        employeAppointment?.map((data, idx) => {
                           const { id, employee_name, email, confirmation_file_list, appointment_file_list } = data
                           return <tr key={idx}>
                              <td class="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-l border-[#E8E8E8]">{employee_name}</td>
                              <td class="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8]">{email}</td>
                              <td class="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8]">
                                 <div className='flex items-center justify-center space-x-3'>
                                    {appointment_file_list ? <><a href={appointment_file_list.file_name} target='_blank' download ><DownloadIcon className='w-6 h-6 cursor-pointer' /></a>
                                       <TrashIcon className='w-6 h-6 cursor-pointer' onClick={() => { handleRemoveDocumentConfirmModal(appointment_file_list.media_id) }}/></> : <PlusIcon className='w-6 h-6 cursor-pointer' onClick={() => handleAppointmentLetter(id, 'APPOINTMENT')} />}
                                 </div>
                              </td>
                              <td class="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-[#E8E8E8]">
                                 <div className='flex items-center justify-center space-x-3'>
                                    {confirmation_file_list ? <><a href={confirmation_file_list.file_name} target='_blank' download ><DownloadIcon className='w-6 h-6 cursor-pointer' /></a>
                                       <TrashIcon className='w-6 h-6 cursor-pointer' onClick={() => { handleRemoveDocumentConfirmModal(confirmation_file_list.media_id) }}/></> : <PlusIcon className='w-6 h-6 cursor-pointer' onClick={() => handleAppointmentLetter(id, 'CONFIRMATION')} />}
                                 </div>
                              </td>
                           </tr>
                        })
                     }
                  </tbody>
               </table>
            </div>
         </div>}
         <Modal open={isOpenModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
            <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[2%]'>
               <div className='document-wrapper px-4 divide-y'>
                  <div className="document-head py-4">
                     <div className='flex items-center justify-between'>
                        <h3 className=' text-gray-700 text-3xl font-semibold capitalize'>Create {letterType === 'APPOINTMENT' ? 'Appointement' : 'Confirmation'} Letter</h3>
                        <div className=' w-6 h-6 cursor-pointer' onClick={() => setIsOpenModal(false)}>
                           <XIcon className=' text-black w-full h-full' />
                        </div>
                     </div>
                  </div>
                  <div className="document-body py-4 overflow-auto max-h-[35rem]">
                     {letterType === 'APPOINTMENT' && <AppointmentLetter data={singleAppointmentData} onClose={handleCloseModal} />}
                     {letterType === 'CONFIRMATION' && <ConfirmationLetter data={singleAppointmentData} onClose={handleCloseModal} />}
                  </div>
               </div>
            </div>
         </Modal>
         <Modals show={isConfirmDeleteModal}>
            <div>
               <p className=' roboto text-center font-bold text-2xl text-gray-700'>Are you sure you want to delete?</p>
               <div className=" mt-12 space-x-3 text-center ">
                  <button className='px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800' onClick={handleDeleteDocument}>Yes</button>
                  <button className='px-4 py-2 rounded-md font-semibold  border' onClick={() => setIsConfirmDeleteModal(false)}>Cancel</button>
               </div>
            </div>
         </Modals>
      </Layout>
   )
}

export default Appointment